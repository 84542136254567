
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import { switchAnatomy } from '@chakra-ui/anatomy'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(switchAnatomy.keys);

const baseStyle = definePartsStyle({
	container: {
		backgroundColor: "transparent",
	},
	track: {
		bgColor: "transparent",
		borderRadius: "full",
		width: "24px",
		height: "16px",
		borderWidth: "2px",
		borderFill: "solid",
		borderColor: "primary.aramcoLinkBlue",
		boxSizing: "border-box",
	},
	thumb: {
		borderRadius: "full",
		bgColor: "primary.aramcoLinkBlue",
		height: "14px",
		width: "14px",
		position: "absolute",
		top: "1px",
		left: "1px",
		transition: "transform linear 250ms",
		_checked: {
			transform: `translateX(8px)`,
		}
	},
});

export const Switch = defineMultiStyleConfig({ baseStyle })
