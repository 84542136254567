import React, { FC, useContext, useEffect, useState } from "react";
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import { type RichTextProps as SitecoreRichTextProps } from '@sitecore-jss/sitecore-jss-react/types/components/RichText';
import { type RichTextField, useSitecoreContext } from "..";
import parse, { Element, HTMLReactParserOptions } from 'html-react-parser';
import { ColorThemeContext } from "~/foundation/Theme/ColorThemeContext";
import { WordExplainer } from "~/foundation/Components/WordExplainer";

type RichTextProps = SitecoreRichTextProps & {
	field?: RichTextField;
	enableWordExplainer?: boolean;
};

function escapeRegExp(str: string) {
	return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

const RichTextWrapper: FC<RichTextProps> = ({ enableWordExplainer, ...props }) => {
	const { sitecoreContext } = useSitecoreContext();
	const isRtl = sitecoreContext.custom.settings.isRtl;
	const [theme] = useContext(ColorThemeContext);
	const [richTextComponent, setRichTextComponent] = useState<JSX.Element>(<RichText {...props} />);

	if (sitecoreContext.pageEditing) {
		return <RichText {...props} />;
	}

	if (!props?.field?.value) {
		return <></>;
	}

	useEffect(() => {
		let index = 0;

		const wordExplainers = sitecoreContext.custom.wordExplainers;
		const wordExplainerKeys = wordExplainers.map(x => x.key.toLowerCase());
		let richTextValue = props.field?.value || "";


		if (enableWordExplainer) {
			const regex = /(<[^>]*>)/;
			const parts = richTextValue.split(regex); // split html-tags and text

			const fullText: string[] = [];
			let ignoreForNow = false;

			for (const part of parts) {
				if (ignoreForNow) {

					// ignore all text withing <a> and <hX> (headers) tags
					if (part.startsWith("</a>") || part.startsWith("</h")) {
						ignoreForNow = false;
					}

					fullText.push(part);
					continue;
				}

				// stop ignoring
				if (part.startsWith("<a") || part.startsWith("<h")) {
					ignoreForNow = true;
					fullText.push(part);
					continue;
				}

				// ignore tag parts
				if (part.startsWith("<")) {
					fullText.push(part);
					continue;
				}

				let htmlPart = part;

				// replace word explainer keys in texts with a span tag
				for (const key of wordExplainerKeys) {
					const escapedKey = escapeRegExp(key);
					const wordRegex = new RegExp(escapedKey, "i");

					htmlPart = htmlPart.replace(wordRegex, (match) => {
						wordExplainerKeys.splice(wordExplainerKeys.indexOf(key), 1);
						return `<span class="word-explainer" data-original-key="${key}">${match}</span>`
					});
				}

				fullText.push(htmlPart);
			}

			richTextValue = fullText.join("");
		}

		const parseOptions: HTMLReactParserOptions = {

			replace: (domNode) => {
				index++;

				if (domNode instanceof Element && domNode.attribs) {
					if (domNode.type === "tag" && domNode.name === "span" && domNode.attribs?.class === "word-explainer") {
						const text = (domNode.children?.[0] as any)?.data; // eslint-disable-line
						const key = domNode.attribs["data-original-key"];
						if (key) {
							const wordExplainer = wordExplainers.find(x => x.key.toLowerCase().trim() === key.toLowerCase()?.trim())

							if (wordExplainer && typeof text === "string") {
								return <WordExplainer isRtl={isRtl} theme={theme} text={text} wordExplainer={wordExplainer} />;
							}
						}
					}

					if (index === 1 && domNode.type === "tag") {
						domNode.attribs.style = "margin-top: 0;";
					}
				}
			}
		};

		const Component = parse(richTextValue, parseOptions) as JSX.Element;
		setRichTextComponent(Component);
	}, []);

	return richTextComponent;
};

export default RichTextWrapper;